import Supplier from '@/Models/Supplier'
import suppliersFilters from '@/views/Suppliers/Data/suppliersFilters'
import axios from 'axios'
import _find from 'lodash/find'
import _indexOf from 'lodash/indexOf'
import _remove from 'lodash/remove'
import Vue from 'vue'

const getDefaultState = () => ({
  suppliers: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  suppliersCategories: {},
  suppliersContactPersons: {},
  filters: suppliersFilters,
  loading: false,
})

const state = getDefaultState()

const actions = {

  async fetchSuppliers({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await Supplier.params({ page })
        .where('categories.id', state.filters.category)
        .where('name', state.filters.name)
        .where('pib', state.filters.pib)
        .get()
      commit('setLoading', false)
      commit('setSuppliers', response)
      commit('setSuppliersCategories', response.data)
      commit('setSuppliersContactPersons', response.data)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  filterSuppliers({ commit, dispatch }, filters) {
    commit('setCurrentPage', 1)
    commit('setFilters', filters)
    dispatch('fetchSuppliers', state.suppliers.current_page)
  },

  async storeSupplier({ dispatch }, payload) {
    const supplier = new Supplier(payload)
    const response = await supplier.save()
    dispatch('fetchSuppliers', state.suppliers.current_page)

    return new Supplier(response)
  },

  async updateSupplier({ dispatch }, payload) {
    const supplier = new Supplier(payload)
    const response = await supplier.save()
    dispatch('fetchSuppliers', state.suppliers.current_page)

    return response
  },

  async destroySupplier({ dispatch, commit }, supplierId) {
    const supplier = new Supplier({ id: supplierId })
    const response = await supplier.delete()
    commit('deleteSupplier', supplierId)

    commit('setCurrentPage', state.suppliers.data.length ? state.suppliers.current_page : state.suppliers.current_page - 1)

    if (state.suppliers.data.length) {
      dispatch('fetchSuppliers', state.suppliers.current_page)
    }

    return response
  },

  storeContactPerson({ commit }, payload) {
    return axios.post('contact_persons', payload).then(response => {
      commit('addContactPerson', { ...response.data, supplierId: payload.supplierId })

      return response
    })
  },

  updateContactPerson({ commit }, payload) {
    return axios.put(`contact_persons/${payload.contactPersonId}`, payload).then(response => {
      commit('updateContactPerson', { ...response.data, supplierId: payload.supplierId })

      return response
    })
  },

  async addCategoryToSupplier(context, payload) {
    return axios.post('suppliers/category/add', payload)
  },

  async uploadAttachments(context, payload) {
    return axios.post('suppliers/documents/add', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',  // Set the correct content type for file upload
      },
    });
  },

  async updateAttachments(context, formData) {
    return axios.post(`supplers/file/update/${formData.get('id')}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',  // Set the correct content type
        },
    });
  },

  async updateCategoryToSupplier(context, payload) {
    return axios.put(`suppliers/category/add/${payload.contact_person_id}`, payload)
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setSuppliers(state, data) {
    state.suppliers = data
  },

  setSuppliersCategories(state, suppliers) {
    suppliers.forEach(supplier => {
      Vue.set(state.suppliersCategories, supplier.id, supplier.categories)
    })
  },

  setSuppliersContactPersons(state, suppliers) {
    suppliers.forEach(supplier => {
      Vue.set(state.suppliersContactPersons, supplier.id, supplier.contact_persons)
    })
  },

  addContactPerson(state, contactPerson) {
    state.suppliersContactPersons[contactPerson.supplierId].push(contactPerson)
  },

  updateContactPerson(state, contactPerson) {
    const index = _indexOf(state.suppliersContactPersons[contactPerson.supplierId], _find(state.suppliersContactPersons[contactPerson.supplierId], ['id', contactPerson.id]))
    if (index !== -1) {
      Vue.set(state.suppliersContactPersons[contactPerson.supplierId], index, contactPerson)
    }
  },

  setCurrentPage(state, page) {
    state.suppliers.current_page = page
  },

  setFilters(state, filters) {
    state.filters = filters
  },

  setLoading(state, value) {
    state.loading = value
  },

  deleteSupplier(state, supplierId) {
    _remove(state.suppliers.data, { id: supplierId })
  },
}

const getters = {
  getSuppliers: state => state.suppliers.data,
  getTotal: state => state.suppliers.total,
  getLastPage: state => state.suppliers.last_page,
  getFilters: state => state.filters,
  getCurrentPage: state => state.suppliers.current_page,
  getLoading: state => state.loading,
  getCategoriesBySupplierId: state => supplierId => state.suppliersCategories[supplierId],
  getContactPersonsBySupplierId: state => supplierId => state.suppliersContactPersons[supplierId],
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
