import axios from 'axios';

const getDefaultState = () => ({
  purchases: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  purchase: null, // Store a single purchase by ID
  loading: false,
});

const state = getDefaultState();

const actions = {
  async fetchDirectPurchases({ commit }, page) {
    try {
      commit('setLoading', true);
      const response = await axios.get(`direct-purchases/get/all?page=${page}`);
      commit('setPurchases', response.data);
      commit('setLoading', false);

      return response;
    } catch (error) {
      commit('setLoading', true);

      return error;
    }
  },
  // New action to fetch a single purchase by ID
  async fetchDirectPurchaseById({ commit }, id) {
    try {
      commit('setLoading', true);
      const response = await axios.get(`direct-purchases/get/${id}`);
      commit('setPurchase', response.data);
      commit('setLoading', false);

      return response;
    } catch (error) {
      commit('setLoading', false);

      return error;
    }
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setPurchases(state, data) {
    state.purchases = data;
  },

  setPurchase(state, data) {
    state.purchase = data; // Mutate state to store the fetched purchase
  },

  setCurrentPage(state, page) {
    state.purchases.current_page = page;
  },

  setLoading(state, value) {
    state.loading = value;
  },
};

const getters = {
  getPurchases: (state) => state.purchases.data,
  getTotal: (state) => state.purchases.total,
  getLastPage: (state) => state.purchases.last_page,
  getCurrentPage: (state) => state.purchases.current_page,
  getLoading: (state) => state.loading,
  getPurchase: (state) => state.purchase, // New getter to retrieve the single purchase
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
