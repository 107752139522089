import {
  mdiNotebookCheckOutline,
  mdiNotebookOutline, mdiNotebookPlusOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Procurements',
    permission: true,
  },
  {
    title: 'New request',
    icon: mdiNotebookPlusOutline,
    to: 'procurements.newRequest',
  },
  {
    title: 'Requests overview',
    icon: mdiNotebookOutline,
    to: 'procurements.requests.index',
  },
  {
    title: 'Purchase confirmation',
    icon: mdiNotebookCheckOutline,
    to: 'procurements.purchaseConfirmation.index',
    permissions: ['owner-scope', 'admin-scope', 'approve-realization-4k-less', 'approve-realization-4k-more'],
  },
  {
    title: 'Ad hoc purchase',
    icon: mdiNotebookOutline,
    to: 'procurements.ad-hoc.index',
    permissions: ['ad-hoc-purchase', 'ad-hoc-approval'],
  },
  {
    title: 'Direct purchase',
    icon: mdiNotebookOutline,
    to: 'procurements.direct-purchase.index',
    permissions: ['direct-initiate', 'direct-approval', 'direct-finances'],
  },
]
