export default {
  DIRECT_INITIATE: 20,
  DIRECT_APPROVAL: 21,
  DIRECT_FINANCES: 22,
  LOCKED: 9,
  AD_HOC_APPROVED: 8,
  AD_HOC: 7,
  PAID: 6,
  RECEIVED: 5,
  CANCELED: 4,
  CLOSED: 3,
  VALUATION: 2,
  ACTIVE: 1,
  REQUESTED: -1,
  INITIATION_APPROVED: -2,
  INITIATED: -3,
}
